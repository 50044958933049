import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import {Chart as ChartJs} from "chart.js/auto";
import {Card, CardBody} from "react-bootstrap";

function DoughnutChart({chartData}) {
  return (
    <Card>
        <CardBody>
            <Doughnut data={chartData}  />
        </CardBody>
    </Card>
  )
}

export default DoughnutChart