import React, { useEffect, useState } from 'react';
import {Row, Col, Card} from "react-bootstrap";
import BarChart from '../../../helper/Charts/BarChart';
import LineChart from '../../../helper/Charts/LineChart';
import PieChart from '../../../helper/Charts/PieChart';
import DoughnutChart from '../../../helper/Charts/DoughnutChart';
import {primary, info, success, warning,gray_900, danger} from '../../../helper/Colors';
import { dashboard } from '../../../helper/api_url';
import { TableResponsive } from '../../../components/common/TableResponsive';


function Index() {
    const [pieChartData,setPichartData] = useState({
        labels:[],
        datasets:[]
    })
    const [dashboardData,setDashboardData] = useState([]);
    useEffect(()=>{
        dashboard.data().then(res=>setDashboardData(res.data)).catch(e=>console.log(e));
    },[]);
    useEffect(()=>{
        setPichartData({
            labels:dashboardData?.attendanceData
                ?Object.keys(dashboardData?.attendanceData)?.filter(i=>i !== 'no_of_students')?.map(d=>d.split('no_of_')[1].toUpperCase())
                :[],
            datasets:[{
                label:'No of Students',
                data:dashboardData.attendanceData
                    ?Object.keys(dashboardData?.attendanceData)?.filter(i=>i !== 'no_of_students')?.map(d=>dashboardData?.attendanceData[d])
                    :[],
                backgroundColor:[success, danger, warning, primary],
            }]
        })
    },[dashboardData])
    const data = [
        { year: 2010, count: 10 },
        { year: 2011, count: 20 },
        { year: 2012, count: 15 },
        { year: 2013, count: 25 },
        { year: 2014, count: 22 },
        { year: 2015, count: 30 },
        { year: 2016, count: 28 },
    ];
    const [chartData,setChartData] = useState({
        labels:data.map(d=>d.year),
        datasets:[{
            label:'Count',
            data:data.map(d=>d.count),
            backgroundColor:[primary, info, success, warning],
            borderColor: primary,
            borderWidth:2,
        }]
    });
    const env_variable = process.env.REACT_APP_TEST;
    return (
        <>
        <Row>
            <Col xl={12}>
                <Card className="crm-widget">
                    <Card.Body className='p-0'>
                        <h1 className='text-danger'>{env_variable}</h1>
                        <Row className="row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                            <Col>
                                <div className="py-4 px-3">
                                    <h5 className="text-muted text-uppercase fs-13"> Total Students <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-info rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{width:'60px',height:'60px'}}>
                                            <i className="ri-team-line display-6 text-white"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.userData?.no_of_students}>{dashboardData?.userData?.no_of_students}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="mt-3 mt-md-0 py-4 px-3">
                                    <h5 className="text-muted text-uppercase fs-13"> Present Students <i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-success rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{width:'60px',height:'60px'}}>
                                            <i className=" ri-user-follow-fill display-6 text-white"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_present_students}>{dashboardData?.attendanceData?.no_of_present_students}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="mt-3 mt-md-0 py-4 px-3">
                                    <h5 className="text-muted text-uppercase fs-13">Absent Students <i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-danger rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{width:'60px',height:'60px'}}>
                                            <i className="ri-user-4-fill display-6 text-white"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_absent_students}>{dashboardData?.attendanceData?.no_of_absent_students}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="mt-3 mt-lg-0 py-4 px-3">
                                    <h5 className="text-muted text-uppercase fs-13">Students On Leave<i className="ri-arrow-up-circle-line text-success fs-18 float-end align-middle"></i></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-warning rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{width:'60px',height:'60px'}}>
                                            <i className="ri-information-fill display-6 text-white"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_leave_students}>{dashboardData?.attendanceData?.no_of_leave_students}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="mt-3 mt-lg-0 py-4 px-3">
                                    <h5 className="text-muted text-uppercase fs-13">Unmarked Attendance<i className="ri-arrow-down-circle-line text-danger fs-18 float-end align-middle"></i></h5>
                                    <div className="d-flex align-items-center">
                                        <div className="bg-dark rounded flex-shrink-0 d-flex align-items-center justify-content-center" style={{width:'60px',height:'60px'}}>
                                            <i className="ri-question-fill display-6 text-light"></i>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h2 className="mb-0"><span className="counter-value" data-target={dashboardData?.attendanceData?.no_of_unmarked_students}>{dashboardData?.attendanceData?.no_of_unmarked_students}</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>                    
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <PieChart chartData={pieChartData} />
            </Col>
            <Col xs={12} md={6}>
                <BarChart chartData={chartData} />
                <Card>
                    <Card.Header>
                        <Card.Title>
                            <h5>Recent Admissions   </h5>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <TableResponsive columns={[]} data={[]} />
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={6}>
                <LineChart chartData={chartData} />
            </Col>
            <Col xs={12} md={6}>
                <DoughnutChart chartData={chartData} />
            </Col>
        </Row>
        </>
        
    )
}

export default Index