import { APPNAME, ASSETURL } from '../config';
import {post,get, del,put, use,API_URL} from './api_helper';
export const ASSET_URL = ASSETURL;
export const APP_NAME= APPNAME;

export const auth={
    login: data =>post("/login",data),
    validate:()=>get('/validate'),
    logout:()=>get("/logout"),
}

export const role={
    list:()=>get("/roles"),
    add:(data)=>post(`/role`,data),
    update:(data)=>post(`/role/change-priority`,data),
    delete:(id)=>del(`/role/${id}`)
}

export const support = {
    addIssue:data=>post(`/issue`,data),
    list:data=>get(`/issues`,data),
    markProgress:id=>get(`/issue/${id}/seen`),
    markResolved:id=>get(`/issue/${id}/resolve`),
    delete:id=>del(`/issue/${id}`),
    comment:(data)=>post('/issue/comment',data),
    commentlist:(pid)=>get(`/issue/${pid}/comments`),
    Commentdelete:(id)=>del(`/issue/comment/${id}`),
}



export const dashboard = {
    data:(filter)=>post('/dashboard',filter),
}

