import Role from "../pages/admin/Role/Role"
import ForgotPassword from "../pages/auth/ForgotPassword"
import Login from "../pages/auth/Login"
import Home from "../pages/public/Home"
import LandingPage from "../pages/public/LandingPage"
import Locked from "../pages/public/Locked"
import Issues from "../pages/public/Support/Issues"

export const authenticatedRoutes = [
   
    // {label:'Profile',path:'/profile',element:<Profile />,users:['admin','staff','student'],children:[]},
    
    {type:'menu',label:'Dashboard',path:'/dashboard',element:<Home />,icon:'ri-home-8-line',users:['admin','user'],children:[]},
    
  
    {type:'setting',label:'Setting',icon:'bx bx-cog',users:['admin','user'],children:[
        {label:'Role',path:'/role',element:<Home/>,users:['admin']},
        {label:'Personalization',path:'/persolization',element:<Home/>}
    ]},
]
export const publicRoutes = [
    {label:'Home',path:'/',element:<Login /> ,icon:'null',users:[],children:[]},
]
export const authRoutes = [
    {label:'Login',path:'/',element:<Login /> ,icon:'null',users:[],children:[]},
    {label:'Login',path:'/login',element:<Login /> ,icon:'null',users:[],children:[]},
    {label:'Password Reset',path:'/passwordReset',element:<ForgotPassword /> ,icon:'null',users:[],children:[]},
]