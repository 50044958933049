// export const APIURL     = 'https://demo.unboxschool.in/backend/api';
// export const HOMEPAGE   = 'https://demo.unboxschool.in/';
// export const START_URL  = 'https://demo.unboxschool.in/';
// export const ASSETURL   = 'https://demo.unboxschool.in/backend/';
// export const APPNAME    = 'Unbox School'

// export const APIURL      = 'https://gurukulacademy.unboxschool.in/backend/api';
// export const HOMEPAGE    = 'https://gurukulacademy.unboxschool.in/';
// export const START_URL   = 'https://gurukulacademy.unboxschool.in/';
// export const ASSETURL    = 'https://gurukulacademy.unboxschool.in/backend/';
// export const APPNAME     = 'Gurukul Academy'

export const APIURL      = 'https://demitra.in/backend/api';
export const HOMEPAGE    = 'https://demitra.in/';
export const START_URL   = 'https://demitra.in/';
export const ASSETURL    = 'https://demitra.in/backend/';
export const APPNAME     = 'Demitra'

